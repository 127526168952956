<template>
  <v-container fluid>
    <v-row>
      <!-- Espace information sur l'utilisateur -->
      <v-col>
        <v-card
          min-width="300px"
          max-width="600px"
          :color="this.$store.state.darkTheme ? card_bg_dark:card_bg_light"
          :dark="this.$store.state.darkTheme ? true:false"
          class="my-4 mx-auto text-center"
        >
          <v-img
            src="@/assets/grenoble-mountain.jpg"
            lazy-src="@/assets/grenoble-mountain.jpg"
          />
          <v-card-title
            class="justify-center"
          >
            <v-icon>mdi-account-circle</v-icon>
            &thinsp;
            {{ this.$store.state.account.firstname }}
            {{ this.$store.state.account.name }}
          </v-card-title>
          <v-card-subtitle>{{ this.$store.state.account.login }}</v-card-subtitle>
          <v-card-subtitle>{{ this.$store.state.account.mail }}</v-card-subtitle>
          <!-- Informations principales -->
          <v-divider
            class="my-4 mx-auto"
            style="width:150px"
          />
          <v-card-text class="mx-auto user-card">
            <v-simple-table
              class="mx-2"
              style="background-color:transparent; min-width:300px; max-width:600px;"
              dense
            >
              <tr
                v-for="(info,key) in this.$store.state.account.infos"
                :key="key"
                class="justify-center"
              >
                <th class="text-right">
                  {{ info.name }}
                </th>
                 - 
                <td class="text-left">
                  {{ info.value }}
                </td>
              </tr>
            </v-simple-table>
          </v-card-text>
          <!-- Roles dans Perseus NG -->
          <v-divider
            class="my-4 mx-auto"
            style="width:150px"
          />
          <v-card-title class="justify-center">
            <v-icon>mdi-target-account</v-icon>
            &thinsp;
            Your role in Perseus :
          </v-card-title>
          <v-card-text>
            <span
              v-for="(item,key) in role"
              :key="key"
            >
              <b>{{ item }}</b>
              <span v-if="key < (role.length - 1)">, </span>
            </span>
          </v-card-text>
          <!-- Poles referents -->
          <v-divider
            class="my-4 mx-auto"
            style="width:150px"
          />
          <v-card-title
            class="justify-center"
          >
            <v-icon>mdi-account-supervisor-circle</v-icon>
            &thinsp;
            Your Pole Referents:
          </v-card-title>
          <v-card-text>
            <v-list
              style="background-color:transparent"
              dense
            >
              <v-list-item
                v-for="(referent,key) in $store.state.listPoleLeader"
                :key="key"
                class="justify-center"
              >
                {{ referent[0] }}
                    &thinsp;
                <a :href="`mailto:${referent[1]}`">{{ referent[1] }}</a>
              </v-list-item>
            </v-list>
          </v-card-text>
          <!-- Parametre : changement de mot de passe -->
          <v-divider
            v-if="!this.$store.state.account.isAgalan"
            class="my-4 mx-auto"
            style="width:150px"
          />
          <v-card-actions v-if="!this.$store.state.account.isAgalan">
            <v-spacer />
            <v-btn
              :dark="this.$store.state.darkTheme ? true:false"
              color="gred"
              x-large
              text
              to="/change-password"
              class="mx-4 my-4"
            >
              <v-icon>mdi-lock-reset</v-icon>
              &ensp;
              Change password
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- Espace des groupes membre -->
      <v-col>
        <v-row :class="(this.$store.state.windowW < 800)?'justify-center':''">
          <v-col cols="12">
            <v-toolbar
              color="transparent"
              class="my-4"
              :dark="this.$store.state.darkTheme ? true : false"
            >
              <v-toolbar-title>
                <h1
                  v-if="!isMobile()"
                  class="font-weight-light"
                >
                  List of your groups
                </h1>
                <h2
                  v-if="isMobile()"
                  class="font-weight-light"
                >
                  List of your groups
                </h2>
              </v-toolbar-title>
            </v-toolbar>
          </v-col>

          <!-- Pole -->
          <v-card
            :color="this.$store.state.darkTheme ? card_bg_dark:card_bg_light"
            :dark="this.$store.state.darkTheme ? true:false"
            class="bloc_groups"
          >
            <v-card-title class="gmiddleblue">
              Pole
            </v-card-title>
            <v-card-text
              v-if="(Object.entries(this.$store.state.listPole).length === 0)"
            >
              No pole
            </v-card-text>
            <v-list
              style="background-color:transparent"
              dense
            >
              <v-list-item
                v-for="(pole,i) in this.$store.state.listPole"
                :key="i"
              >
                {{ pole }}
              </v-list-item>
            </v-list>
          </v-card>

          <!-- Laboratory -->
          <v-card
            :color="this.$store.state.darkTheme ? card_bg_dark:card_bg_light"
            :dark="this.$store.state.darkTheme ? true:false"
            class="bloc_groups"
          >
            <v-card-title class="gmiddleblue">
              Laboratories
            </v-card-title>
            <v-card-text
              v-if="(Object.entries(this.$store.state.listLab).length === 0)"
            >
              No laboratory
            </v-card-text>
            <v-list
              style="background-color:transparent"
              dense
            >
              <v-list-item
                v-for="(lab,i) in this.$store.state.listLab"
                :key="i"
              >
                {{ lab }}
              </v-list-item>
            </v-list>
          </v-card>

          <!-- Functionnal group -->
          <v-card
            :color="this.$store.state.darkTheme ? card_bg_dark:card_bg_light"
            :dark="this.$store.state.darkTheme ? true:false"
            class="bloc_groups"
          >
            <v-card-title class="gmiddleblue">
              Functional group
            </v-card-title>
            <v-card-text
              v-if="(Object.entries(this.$store.state.listFunctionalGroup).length === 0)"
            >
              No functional group
            </v-card-text>
            <v-list
              style="background-color:transparent"
              dense
            >
              <v-list-item
                v-for="(fgroup,i) in this.$store.state.listFunctionalGroup"
                :key="i"
              >
                {{ fgroup }}
              </v-list-item>
            </v-list>
          </v-card>

          <!-- Projects as member -->
          <v-card
            :color="this.$store.state.darkTheme ? card_bg_dark:card_bg_light"
            :dark="this.$store.state.darkTheme ? true:false"
            class="bloc_groups"
          >
            <v-card-title class="gmiddleblue">
              Projects as member
            </v-card-title>
            <v-card-text
              v-if="(Object.entries(this.$store.state.joinedProjects.projects).length === 0)"
            >
              No project
            </v-card-text>
            <v-list
              style="background-color:transparent"
              dense
            >
              <v-list-item
                v-for="(project,i) in this.$store.state.joinedProjects.projects"
                :key="i"
              >
                <router-link :to="`/my-projects/project/${project.name}`">
                  {{ project.name }}
                </router-link>
              </v-list-item>
            </v-list>
          </v-card>

          <!-- Projects as admin -->
          <v-card
            v-if="this.$store.state.permanent"
            :color="this.$store.state.darkTheme ? card_bg_dark:card_bg_light"
            :dark="this.$store.state.darkTheme ? true:false"
            class="bloc_groups"
          >
            <v-card-title class="gmiddleblue">
              Projects as admin
            </v-card-title>
            <v-card-text
              v-if="(Object.entries(this.$store.state.createdProjects.projects).length === 0)"
            >
              No project
            </v-card-text>
            <v-list
              v-if="this.$store.state.permanent"
              style="background-color:transparent"
              dense
            >
              <v-list-item
                v-for="(project,i) in this.$store.state.createdProjects.projects"
                :key="i"
              >
                <router-link :to="`/my-projects/project/${project.name}`">
                  {{ project.name }}
                </router-link>
              </v-list-item>
            </v-list>
          </v-card>

          <!-- Referents (pour les externes) -->
          <v-card
            v-if="this.$store.state.extern"
            :color="this.$store.state.darkTheme ? card_bg_dark:card_bg_light"
            :dark="this.$store.state.darkTheme ? true:false"
            class="bloc_groups"
          >
            <v-card-title class="gmiddleblue">
              Referents
            </v-card-title>
            <v-card-text
              v-if="(Object.entries(this.$store.state.listReferent).length === 0)"
            >
              No referent
            </v-card-text>
            <v-list
              style="background-color:transparent"
              dense
            >
              <v-list-item
                v-for="(uid,i) in this.$store.state.listReferent"
                :key="i"
              >
                {{ uid }}
              </v-list-item>
            </v-list>
          </v-card>

          <!-- Mailing list -->
          <v-card
            :color="this.$store.state.darkTheme ? card_bg_dark:card_bg_light"
            :dark="this.$store.state.darkTheme ? true:false"
            class="bloc_groups"
          >
            <v-card-title class="gmiddleblue">
              Mailing list
            </v-card-title>
            <v-card-text
              v-if="(Object.entries(this.$store.state.listMailingList).length === 0)"
            >
              No subscription
            </v-card-text>
            <v-list
              style="background-color:transparent"
              dense
            >
              <v-list-item
                v-for="(mailingl,i) in this.$store.state.listMailingList"
                :key="i"
              >
                {{ mailingl }}
              </v-list-item>
            </v-list>
          </v-card>

          <!-- Machines -->
          <v-card
            :color="this.$store.state.darkTheme ? card_bg_dark:card_bg_light"
            :dark="this.$store.state.darkTheme ? true:false"
            class="bloc_groups"
          >
            <v-card-title class="gmiddleblue">
              Machine
            </v-card-title>
            <v-card-text
              v-if="(Object.entries(this.$store.state.listMachine).length === 0)"
            >
              No machine
            </v-card-text>
            <v-list
              style="background-color:transparent"
              dense
            >
              <v-list-item
                v-for="(machine,i) in this.$store.state.listMachine"
                :key="i"
              >
                {{ machine }}
              </v-list-item>
            </v-list>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <br>
  </v-container>
</template>

<script>
  // import axios from 'axios'
  import config from '@/config'
  export default {
    data () {
      return {
        // Couleur
        red: config.colors.gricad_red_dark1,
        card_bg_dark: config.colors.card_bg_dark,
        card_bg_light: config.colors.card_bg_light,
        barchart_color: config.colors.gricad_blue_dark3,
        blue: config.colors.gricad_oceanblue,

        role: ['user'],
        list_joinedProject: [],
        list_createdProject: [],
        list_machine: [],
        list_pole: [],
        list_laboratory: [],
        list_functionalGroup: [],
        list_mailingList: [],

        contractDate: '30/11/1994',
        mail: 'thomas.coupechoux@univ-grenoble-alpes.fr',
        projects: ['pr-admin', 'pr-funproject', 'pr-time-travelers'],
        mailingList: ['li-users', 'li-cigri', 'li-maimosine'],
        machines: ['m-luke', 'm-dahu', 'm-openstack'],
        groupLists: ['f-admins', 'f-reviewer'],
        poles: ['p-mstic'],
        labos: ['l-gricad'],
        lists: [
          {
            cn: 'li-users',
            description: 'list for all users',
          },
          {
            cn: 'li-dahu',
            description: 'list for dahu users',
          },
        ],
        checked: [true, false],
        status: 'Active',
      }
    },

    created () {
      if (this.$store.state.permanent) {
        this.role.push('permanent')
      }
      if (this.$store.state.admin) {
        this.role.push('admin')
      }
      if (this.$store.state.reviewer) {
        this.role.push('reviewer')
      }
    },

    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
    },
  }
</script>

<style lang="scss">
  .user-card {
    overflow-x: auto;
    margin-right: 2pc;

    td {
      width: 50%;
    }
  }

  /*.list {
    background-color: transparent;
  }
*/
  .bloc_groups {
    margin-left: 10pt;
    margin-right: 10pt;
    margin-top: 10pt;
    margin-bottom: 10pt;
    max-width: 500px;
    min-width: 250px;
    overflow-x: auto;
  }

  table {
    background-color: transparent;
  }
</style>
